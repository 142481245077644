import React from "react";
import { Dialog } from "@material-tailwind/react";
import successTick from "../../../assets/successTick.png"

type successModalType = {
  dialogProps: {
    open: boolean;
    handler: () => void;
    size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  };
  modalHandler?: () => void;
};

export default function SuccessModal(props: successModalType) {
  return (
    
    <Dialog
      open={props?.dialogProps?.open}
      handler={props?.dialogProps?.handler}
      size={props?.dialogProps?.size}
    >
        <div className="font-nunito">
      <div
        onClick={props.dialogProps?.handler}
        className="flex hover:cursor-pointer text-black justify-end text-2xl p-5"
      >
        x
      </div>
      <div className="flex flex-col justify-center items-center gap-5">
        <div className="flex flex-row">
            <img src={successTick} alt="Success Tick"/>
        </div>
        <div className="flex flex-row"> 
        Registered Successfully
        </div>
        <div className="flex flex-row">
        Our Team will get back to you soon !
        </div>
      </div>
      <div className="h-10"></div>
      </div>
    </Dialog>
  );
}