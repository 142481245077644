import React from 'react'

type contentType = {
    contentHeader: string;
    contentDescription: string;
}

export default function Content(props:contentType) {
  return (
    <div className='flex flex-col justify-items-center text-center text-white p-10'>
        
            <h1 className="font-nunito text-7xl font-semibold">{props?.contentHeader}</h1>
            <p className='text-3xl mx-10'>{props?.contentDescription}</p>
        
    </div>
  )
}

