import React from 'react';
import { Dialog} from '@material-tailwind/react'
import modalBack from "../../../../assets/modalBack.png"

type SecondModalPropsType={
  DialogProps:{
    open: boolean;
    handler: (value:any)=>void;
    size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
},
  modalHandler?: ()=> void,
  navigate?: ()=> void
}

export default function secondModal(props:SecondModalPropsType) {
  return (
    <Dialog
          open={props.DialogProps?.open}
          handler={props.DialogProps?.handler}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          size={props.DialogProps?.size}
        >
            <div className='flex flex-row gap-24'>
              <div className='flex flex-col w-full'>
                <img src={modalBack} alt="Modal Images" className='w-full h-full'/>
              </div>
            <div className="flex flex-col w-full gap-10 ">
              <div onClick={props.DialogProps?.handler} className="flex hover:cursor-pointer text-black justify-end text-2xl p-5">
                X
              </div>
              <div className="flex text-2xl text-black">
              Prizes Await:
              </div>
              <div className='text-[#747474] flex flex-col justify-start items-start'>
                <div>1. 1st Prize: Grand Winner (Based on YouTube Views & Likes)</div>
                <div>2. 2nd Prize: Second Place (Based on YouTube Views & Likes)</div>
                <div>3. 3rd Prize: Third Place (Based on YouTube Views & Likes)</div>
                <div>4. Instagram Prize: Most Liked Video on Instagram</div>

              </div>
              <div className="flex text-center text-2xl text-black">
              Winner Selection:
              </div>
              <div className='text-[#747474] flex flex-col justify-start items-start'>
                <div>- Your video will be uploaded to the official Truprops YouTube channel for one week.</div>
                <div>- After a week, the views and likes of each video will be tallied.</div>
                <div>- The video with the highest views and likes will be crowned the winner, along with the second and third prizes.</div>
                <div>- The Instagram prize will be awarded to the video with the most likes on our Instagram page.</div>

              </div>
              <div className='flex justify-between mt-10'>
              <button className='bg-[#EDEDED] w-24 border rounded-md' onClick={props?.modalHandler}>Previous</button>
              <button onClick={props?.navigate} className='bg-[#F7941E]  w-24 border rounded-md text-white mr-20'>Continue</button></div>
            </div>
            </div>
        </Dialog>
  )
}
