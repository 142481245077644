import React from 'react'
import { useParams } from 'react-router-dom'
import { Header } from '../../layout/layout';
import Form from './form/form';

export default function Registration() {
  const { fromdetails } = useParams();
  
  if (fromdetails) {
    return (<>
    <Header mainheader={false}/>
    <Form/>
    </>
    );
  }
  else return null; 
}
