import React from 'react'
import { Dialog } from '@material-tailwind/react'
import modalBack from "../../../../assets/modalBack.png"

type modalHandleDataType = {
  DialogProps:{
    open: boolean;
    handler: (value:any)=>void;
    size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
},
  modalHandler?: ()=> void,
}

export default function FirstModal(props:modalHandleDataType) {
  return (
    <>
      <div>
        <Dialog
          open={props?.DialogProps?.open}
          handler={props?.DialogProps?.handler}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          size={props?.DialogProps?.size}
        >
            <div className='flex flex-row gap-24 font-nunito'>
              <div className='flex flex-col w-full'>
                <img src={modalBack} alt="Modal Image" className='w-full h-full'/>
              </div>
            <div className="flex flex-col w-full gap-10">
              <div onClick={props?.DialogProps?.handler} className="flex hover:cursor-pointer text-black justify-end text-2xl p-5">
                X
              </div>
              <div className="flex w-5/6 text-2xl text-black">
                Event Details :
              </div>
              <div className='text-[#747474] flex flex-col justify-start items-start'>
                <div>Date: 28/08/2023</div>
                <div>Time: Day time</div>
                <div>Theme: Truprops</div>
                <div>Duration: 5-7 minutes</div>

              </div>
              <div className="flex text-center w-5/6 text-2xl text-black">
              How to Participate:
              </div>
              <div className='text-[#747474] flex flex-col justify-start items-start'>
                <div>1. Assemble your dynamic dance crew.</div>
                <div>2. Create an energetic dance routine inspired by the Truprops theme.</div>
                <div>3. Record your performance in a high-quality video (5-7 minutes).</div>
                <div>4. Upload the video to your personal Gmail Drive and grant access to us for viewing.</div>

              </div>
              <div className='flex justify-between mt-10'>
              <button disabled className=' bg-[#EDEDED] w-24 border rounded-md hover:cursor-not-allowed'>Previous</button>
              <button onClick={props?.modalHandler} className='bg-[#F7941E]  w-24 border rounded-md text-white mr-20'>Next</button></div>
            </div>
            </div>
        </Dialog>
      </div>
    </>
  )
}
