import React from 'react';
import blackLogo from "../../assets/blackLogo.png"
import whiteLogo from "../../assets/whiteLogo.png"
import ContactModal from './modal/contactModal';
import { useNavigate } from 'react-router-dom';

type sizeType = "sm" | "md" | "xs" | "lg" | "xl" | "xxl"

export default function Header({ mainheader = true }) {
  const [size, setSize] = React.useState<sizeType | null >(null);
  const handler = (value:sizeType | null) => setSize(value);
  const navigate = useNavigate();
    return (
    <>
    {
        mainheader ? 
            <div className="flex flex-wrap p-4 justify-between w-screen content-center"> 
                <img src={whiteLogo} className="ml-10 hover:cursor-pointer" alt="Truprops Logo" />
                    <button type="button" className="border rounded-xl w-36 h-10 text-cyan-50 mr-10 mt-3" onClick={() =>handler("xs")}>Contact us</button>
                </div> 
        : 
        <div className="flex flex-wrap p-4 justify-between w-screen content-center shadow-md"> 
        <img src={blackLogo} className="ml-10 hover:cursor-pointer" alt="Truprops Logo" onClick={()=> navigate("/")}/>
            <button type="button" className="border rounded-xl w-36 h-10 mr-10 mt-3" onClick={() =>handler("xs")}>Contact us</button>
        </div>
    }
    <ContactModal handler={handler} open={size === "xs" } children size={'xs'}/>
    </>
    );
}
