import React from "react";
import danceImage from "../../../assets/danceImage.png";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import SuccessModal from "./successModal";

export default function Form() {
  const occupation = [
    {
      id: "1",
      name: "Student"
    },
    {
      id: "2",
      name: "Employed"
    },
    {
      id: "3",
      name: "Unemployee"
    },
    {
      id: "4",
      name: "Own business"
    }
  ];
  const teamSize = [
    {
      id: "1",
      name: "3"
    },
    {
      id: "2",
      name: "4"
    },
    {
      id: "3",
      name: "5"
    },
    {
      id: "4",
      name: "6"
    },
    {
      id: "5",
      name: "6+"
    }
  ];
  const phoneNumberRegex = /^\d{10}$/;
  const [states, setStates] = React.useState<[]>();
  const [cities, setCities] = React.useState<[]>();
  const [selectedState, setSelectedState] = React.useState<any>();
  const [successModalOpen, setSuccessModalOpen] = React.useState<boolean>(false)
  const handleSuccessModal = ()=> {
    setSuccessModalOpen(!successModalOpen);
  }
  const schema = z.object({
    name: z
      .string()
      .min(3, { message: "Enter valid name" })
      .refine((value) => value.length === value.trim().length, {
        message: "Please remove extra spaces",
      }),
    mobileNumber: z
      .number({ invalid_type_error: "Please enter a mobile number" })
      .refine((value) => phoneNumberRegex.test(String(value)), {
        message: "Please enter a valid mobile number",
      }),
    email: z.string().email(),
    address: z.string().min(5, { message: "Enter valid address" }),
    state: z.string().min(3, { message: "Select State" }),
    city: z.string().min(3, { message: "Select City" }),
    occupation: z.string().min(3, { message: "Select Occupation" }),
    teamSize: z.string().min(1, { message: "Select Team Size" }),
  });

  type UseFormState = z.infer<typeof schema>;
  type getConutriesType = {
    country: string;
  };
  type getCitiesType = {
    country: string;
    state: string
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<UseFormState>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: UseFormState) => {
    console.log(data);
    handleSuccessModal();
    reset();
  };

  const getStatesInCountry = async (data: getConutriesType) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };

    await fetch(
      "https://countriesnow.space/api/v0.1/countries/states",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("coutry staate", result?.data?.states);
        setStates(result?.data?.states);
      })
      .catch((error) => console.log("error", error));
  };


  const getCitiesInState = async (data: getCitiesType) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };

    await fetch(
      "https://countriesnow.space/api/v0.1/countries/state/cities",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("coutry staate", result?.data);
        setCities(result?.data);
      })
      .catch((error) => console.log("error", error));
  };

  React.useEffect(() => {
    getStatesInCountry({ country: "India" });
  }, []);
  React.useEffect(() => {
    getCitiesInState({ country: "India" , state: `${selectedState}`});
  }, [selectedState]);
  return (
    <div className="p-16 font-nunito flex flex-row w-screen">
      <div className="flex flex-col w-2/3">
        <div>Fill form to register</div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <label>Name:</label>
            <input
              {...register("name")}
              className="w-full h-8 rounded border border-gray-800 justify-start items-center gap-2.5 inline-flex"
              placeholder="Name"
            />
            {errors?.name && (
              <p className="text-red-300">{errors?.name?.message}</p>
            )}
            <div className="flex flex-row gap-10 w-full">
              <div className=" flex flex-col gap-5 w-1/2">
                <label className="flex flex-row w-full">Mobile number:</label>
                <input
                  type="tel"
                  {...register("mobileNumber", { valueAsNumber: true })}
                  className="flex flex-row w-full justify-self-stretch h-8 rounded border border-gray-800 items-center gap-2.5"
                  placeholder="Mobile Number eg: 99990 01111"
                />
                {errors?.mobileNumber && (
                  <p className="text-red-300">
                    {errors?.mobileNumber?.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-5 w-1/2">
                <label className="flex flex-row w-full">Email ID:</label>
                <input
                  {...register("email")}
                  className="flex flex-row justify-self-stretch w-full h-8 rounded border border-gray-800 items-center gap-2.5"
                  placeholder="Your email"
                />
                {errors?.email && (
                  <p className="text-red-300">{errors?.email?.message}</p>
                )}
              </div>
            </div>
            <label>Address:</label>
            <textarea
              {...register("address")}
              className="w-full h-36 rounded border border-gray-800 justify-start items-center gap-2.5"
              placeholder="Full address"
            />
            {errors?.address && (
              <p className="text-red-300">{errors?.address?.message}</p>
            )}
            <div className="flex flex-row gap-10 w-full">
              <div className="flex flex-col gap-5 w-1/2">
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <label> Select State: </label>
                  <select className="h-8 rounded border border-gray-800 justify-start"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e); // This is important to update the form's value
                      setSelectedState(e.target.value); // Update the state
                    }}
                  >
                    <option value="">Select state</option>
                    {states && Array.isArray(states) && states.map((data:any)=>
                    (<option value={`${data?.name}`}> {data?.name}</option>)
                    )}
                  </select>
                </>
              )}
            />
            {errors && <p className="text-red-300"> {errors?.state?.message} </p>}
            </div>
            <div className="flex flex-col gap-5 w-1/2">
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <label> Select City: </label>
                  <select className="h-8 rounded border border-gray-800 justify-start"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e); // This is important to update the form's value
                      console.log("asdfghjklxcvbnm",e.target.value); // Update the state
                    }}
                  >
                    <option value="">Select city</option>
                    {cities && Array.isArray(cities) && cities.map((data:any)=>
                    (<option value={`${data}`}> {data}</option>)
                    )}
                  </select>
                </>
              )}
            />
            {errors && <p className="text-red-300"> {errors?.city?.message} </p>}
            </div>
            </div>
            <div className="flex flex-row gap-10 w-full">
              <div className="flex flex-col gap-5 w-1/2">
            <Controller
              name="occupation"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <label> Select Occupation: </label>
                  <select className="h-8 rounded border border-gray-800 justify-start"
                    {...field}
                  >
                    <option value="">Select occupation</option>
                    {occupation && Array.isArray(occupation) &&occupation.map((data:any)=>
                    (<option value={`${data?.name}`}> {data?.name}</option>)
                    )}
                  </select>
                </>
              )}
            />
            {errors && <p className="text-red-300"> {errors?.occupation?.message} </p>}
            </div>
            <div className="flex flex-col gap-5 w-1/2">
            {teamSize && Array.isArray(teamSize) &&
            <Controller
              name="teamSize"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <label> Team Size: </label>
                  <select className="h-8 rounded border border-gray-800 justify-start"
                    {...field}
                  >
                    <option value="">Team Count</option>
                    {teamSize && teamSize.map((data:any)=>
                    (<option value={`${data?.name}`}> {data?.name}</option>)
                    )}
                  </select>
                </>
              )}
            />}
            {errors && <p className="text-red-300"> {errors?.teamSize?.message} </p>}
            </div>
            </div>
            
            <button type="submit" disabled={isSubmitting} className="bg-[#F7941E] rounded h-12 text-white font-extrabold">
              {" "}
              Submit
            </button>
          </form>
        </div>
        <SuccessModal dialogProps={{
          open:successModalOpen,
          handler: handleSuccessModal,
          size: "xs"
        }

        }/>
      </div>
      <div className="flex flex-col w-1/3 justify-items-center items-center gap-8 mt-10">
        <img src={danceImage} alt="dance" className="flex flex-row" />
        <div className="flex flex-row text-3xl">
          {" "}
          Register & Win Exciting Prices !
        </div>
      </div>
    </div>
  );
}
