import React from 'react';
import { Header } from '../../layout/layout';
import content from "./content.json"
import { Content, Apply } from './section/section';

export default function Home() {
  return (
    <div className="flex bg-[url('/src/assets/bg.jpg')] bg-no-repeat bg-cover bg-bottom h-screen">
      <div>
      <Header/>
      <div className='flex justify-items-center align-middle flex-col mt-10'>
      <Content contentHeader={content?.contentHeader} contentDescription={content?.contentDescription}/>
      <Apply/>
      </div>
      </div>
    </div>
  )
}
