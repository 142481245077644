import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Router from './router/router';
import ErrorBoundary from './ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>

      <Router/>
    </ErrorBoundary>
  );
}

export default App;
