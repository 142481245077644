import React from "react";
import { Dialog, DialogBody, DialogProps } from "@material-tailwind/react";
import phone from "../../../assets/phone.png";
import email from "../../../assets/email.png";

export default function ContactModal(props: DialogProps) {
  return (
    <>
      <div>
        <Dialog
          open={props?.open}
          handler={props?.handler}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          size={props?.size}
        >
          <DialogBody>
            <div className="flex justify-between text-2xl">
              <div className="text-center w-5/6">
                If you have any Queries Feel Free to Contact Us !
              </div>
              <div onClick={props?.handler} className="hover:cursor-pointer text-black">
                X
              </div>
            </div>
            <div className="flex flex-col m-5 gap-5">
              <div className="flex flex-row gap-3">
                <img src={phone} alt="phone-icon" />
                <div className="text-xl ml-1"> +91 91500 80346</div>
              </div>
              <div className="flex flex-row gap-3">
                <img src={email} alt="email-icon" />
                <div className="text-xl"> info@truprops.com</div>
              </div>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </>
  );
}
