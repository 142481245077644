import {
    Route, Routes, BrowserRouter, Navigate
} from "react-router-dom"
import { Home, Registration, EventDetails } from "../pages/pages"

export default function Router (){
    return (
        <BrowserRouter>
        <Routes >
            <Route element={<Home/>} path="/"/>
            <Route element={<EventDetails/>} path="/details"/>
            <Route element={<Registration/>} path="/details/register/:fromdetails"/>
            <Route element={<Navigate to="/details"/>} path="/details/register"/>

        </Routes>
        </BrowserRouter>
    )
}