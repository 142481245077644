import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Apply() {
  const navigate = useNavigate();
  return (
    <div>
        <div className='flex flex-col justify-items-center text-center text-white'>
            <div><button type='button' className='text-black border rounded-xl w-48 h-10 bg-white' onClick={()=>navigate("/details")}> Register Here</button></div>
            <div className='p-2'><p>*Apply Before 28/08/2023</p></div>
        </div>
    </div>
  )
}
