import React from "react";
import { useNavigate } from 'react-router-dom'
import logoWhite from "../../assets/whiteLogo.png";
import { FirstModal, SecondModal } from "./eventModal/eventModal";

export default function EventDetails() {
  const navigate = useNavigate();
  const useID = React.useId();
  const [showFirstModal, setShowFirstModal] = React.useState<boolean>(true);
  const handleFirstModal = () => {
    setShowSecondModal(false);
    setShowFirstModal(!showFirstModal);
  };
  const [showSecondModal, setShowSecondModal] = React.useState<boolean>(false);
  const handleSecondModal = () => {
    setShowFirstModal(!showFirstModal)
    setShowSecondModal(!showSecondModal);
  };
  const navigateHandler = () => {
    navigate(`/details/register/${useID}`)
  }
  return (
    <div className="flex bg-[#F79E41] h-screen p-5 justify-center items-center flex-col gap-10">
      <div className='flex flex-row'>
        <img src={logoWhite} className="" alt=""/>
      </div>
      <FirstModal
        DialogProps={{
          open: showFirstModal,
          handler: handleFirstModal,
          size: "xl",
        }}
        modalHandler={handleSecondModal}
      />
      <SecondModal
        DialogProps={{
          open: showSecondModal,
          handler: handleSecondModal,
          size: "xl",
        }}
        modalHandler={handleFirstModal}
        navigate={navigateHandler}
      />
      <div className="flex flex-row gap-10">
        <button type="button" className="bg-[#222] w-24 text-white rounded-md" onClick={() => navigate("/")}>
          Go home
        </button>
        <button type="button" className=" w-24 rounded-md bg-white text-black " onClick={() => handleFirstModal()}>
          Register
        </button>
      </div>
    </div>
  );
}
